import { CustomAttributeTypeDTO, InstalledDataSystemRelationshipTypeV2DTO } from '@ketch-com/figurehead'
import { ChipsAutocompleteOptionType, theme } from '@ketch-com/deck'
import { ChipContentRadioEditOptions } from 'components/InfoRow/ChipContentRadioEditInfoRow'
import { PolicyDocumentDTO } from 'interfaces/policyDocuments/policyDocument'
import { RadioOptionValue } from 'components/RadioGroup/RadioGroup'

export type UserAndTeamOptionType = ChipsAutocompleteOptionType & { name: string; id: string }
export type DocumentOptionType = PolicyDocumentDTO & { value: string }

export enum PersonalDataTypes {
  NOT_DEFINED = '0',
  PERSONAL = '1',
  SENSITIVE = '2',
  NON_PERSONAL = '3',
}
export const PersonalDataTypeOptions: ChipContentRadioEditOptions = {
  [PersonalDataTypes.NOT_DEFINED]: { title: 'Not Defined', value: PersonalDataTypes.NOT_DEFINED },
  [PersonalDataTypes.PERSONAL]: { title: 'Personal', value: PersonalDataTypes.PERSONAL },
  [PersonalDataTypes.SENSITIVE]: { title: 'Sensitive', value: PersonalDataTypes.SENSITIVE },
  [PersonalDataTypes.NON_PERSONAL]: { title: 'Non Personal', value: PersonalDataTypes.NON_PERSONAL },
}

export enum RetentionPeriodType {
  DAYS = 0,
  MONTH = 1,
  YEARS = 2,
}
export const RetentionPeriodTypeOptions = {
  [RetentionPeriodType.DAYS]: { title: 'Days', value: '0' },
  [RetentionPeriodType.MONTH]: { title: 'Month', value: '1' },
  [RetentionPeriodType.YEARS]: { title: 'Years', value: '2' },
}

export enum SecurityMeasures {
  SECURITY_MEASURE_ENCRYPTION_AT_REST = 0,
  SECURITY_MEASURE_ENCRYPTION_IN_TRANSIT = 1,
  SECURITY_MEASURE_PRINCIPAL_OF_LEAST_PRIVILEGE = 2,
  SECURITY_MEASURE_PHYSICAL_SECURITY = 3,
  SECURITY_MEASURE_USER_ROLES = 4,
  SECURITY_MEASURE_ACCESS_CONTROL_POLICIES = 5,
}
export const securityMeasureOptions: Record<number, RadioOptionValue> = {
  [SecurityMeasures.SECURITY_MEASURE_ENCRYPTION_AT_REST]: {
    title: 'Encryption at rest',
    value: SecurityMeasures.SECURITY_MEASURE_ENCRYPTION_AT_REST,
  },
  [SecurityMeasures.SECURITY_MEASURE_ENCRYPTION_IN_TRANSIT]: {
    title: 'Encryption in transit',
    value: SecurityMeasures.SECURITY_MEASURE_ENCRYPTION_IN_TRANSIT,
  },
  [SecurityMeasures.SECURITY_MEASURE_PRINCIPAL_OF_LEAST_PRIVILEGE]: {
    title: 'Principal of least privilege',
    value: SecurityMeasures.SECURITY_MEASURE_PRINCIPAL_OF_LEAST_PRIVILEGE,
  },
  [SecurityMeasures.SECURITY_MEASURE_PHYSICAL_SECURITY]: {
    title: 'Physical security',
    value: SecurityMeasures.SECURITY_MEASURE_PHYSICAL_SECURITY,
  },
  [SecurityMeasures.SECURITY_MEASURE_USER_ROLES]: {
    title: 'User roles',
    value: SecurityMeasures.SECURITY_MEASURE_USER_ROLES,
  },
  [SecurityMeasures.SECURITY_MEASURE_ACCESS_CONTROL_POLICIES]: {
    title: 'Access control policies',
    value: SecurityMeasures.SECURITY_MEASURE_ACCESS_CONTROL_POLICIES,
  },
}

export const TypeOptions: ChipContentRadioEditOptions = {
  [InstalledDataSystemRelationshipTypeV2DTO.PartnerInstalledDataSystemRelationshipTypeV2]: {
    title: 'Partner',
    value: InstalledDataSystemRelationshipTypeV2DTO.PartnerInstalledDataSystemRelationshipTypeV2,
  },
  [InstalledDataSystemRelationshipTypeV2DTO.CustomerInstalledDataSystemRelationshipTypeV2]: {
    title: 'Customer',
    value: InstalledDataSystemRelationshipTypeV2DTO.CustomerInstalledDataSystemRelationshipTypeV2,
  },
  [InstalledDataSystemRelationshipTypeV2DTO.VendorInstalledDataSystemRelationshipTypeV2]: {
    title: 'Vendor',
    value: InstalledDataSystemRelationshipTypeV2DTO.VendorInstalledDataSystemRelationshipTypeV2,
  },
  [InstalledDataSystemRelationshipTypeV2DTO.ClientInstalledDataSystemRelationshipTypeV2]: {
    title: 'Client',
    value: InstalledDataSystemRelationshipTypeV2DTO.ClientInstalledDataSystemRelationshipTypeV2,
  },
  [InstalledDataSystemRelationshipTypeV2DTO.UnknownInstalledDataSystemRelationshipTypeV2]: {
    title: 'Unknown',
    value: InstalledDataSystemRelationshipTypeV2DTO.UnknownInstalledDataSystemRelationshipTypeV2,
  },
} as const

export const TypeFilterOptions: ChipContentRadioEditOptions = {
  [InstalledDataSystemRelationshipTypeV2DTO.PartnerInstalledDataSystemRelationshipTypeV2]: {
    title: 'Partner',
    value: InstalledDataSystemRelationshipTypeV2DTO.PartnerInstalledDataSystemRelationshipTypeV2,
  },
  [InstalledDataSystemRelationshipTypeV2DTO.CustomerInstalledDataSystemRelationshipTypeV2]: {
    title: 'Customer',
    value: InstalledDataSystemRelationshipTypeV2DTO.CustomerInstalledDataSystemRelationshipTypeV2,
  },
  [InstalledDataSystemRelationshipTypeV2DTO.VendorInstalledDataSystemRelationshipTypeV2]: {
    title: 'Vendor',
    value: InstalledDataSystemRelationshipTypeV2DTO.VendorInstalledDataSystemRelationshipTypeV2,
  },
  [InstalledDataSystemRelationshipTypeV2DTO.ClientInstalledDataSystemRelationshipTypeV2]: {
    title: 'Client',
    value: InstalledDataSystemRelationshipTypeV2DTO.ClientInstalledDataSystemRelationshipTypeV2,
  },
} as const

export const CustomAttributeTypeOptions: Record<string, CustomAttributeTypeDTO> = {
  text: CustomAttributeTypeDTO.TextCustomAttributeType,
  single_select: CustomAttributeTypeDTO.SingleSelectCustomAttributeType,
  multi_select: CustomAttributeTypeDTO.MultiSelectCustomAttributeType,
}
interface DataListTypeFilterOption {
  title: string
  value: number
}

export const TypeListFilterOptions: DataListTypeFilterOption[] = [
  {
    title: 'Partner',
    value: 1,
  },
  {
    title: 'Customer',
    value: 2,
  },
  {
    title: 'Vendor',
    value: 3,
  },
  {
    title: 'Client',
    value: 4,
  },
]

// Replace with generated type when available
export enum InherentRiskScore {
  Unassessed = 'INHERENT_RISK_SCORE_UNSPECIFIED',
  Low = 'INHERENT_RISK_SCORE_LOW',
  Medium = 'INHERENT_RISK_SCORE_MEDIUM',
  High = 'INHERENT_RISK_SCORE_HIGH',
  Critical = 'INHERENT_RISK_SCORE_CRITICAL',
}

export type InherentRiskScores =
  | 'INHERENT_RISK_SCORE_UNSPECIFIED'
  | 'INHERENT_RISK_SCORE_LOW'
  | 'INHERENT_RISK_SCORE_MEDIUM'
  | 'INHERENT_RISK_SCORE_HIGH'
  | 'INHERENT_RISK_SCORE_CRITICAL'

export interface InherentRiskLevelOption {
  title: string
  value: InherentRiskScores
}

export const inherentRiskLevelOptions: Record<string, InherentRiskLevelOption> = {
  [InherentRiskScore.Unassessed]: {
    title: 'Unassessed',
    value: InherentRiskScore.Unassessed,
  },
  [InherentRiskScore.Low]: {
    title: 'Low',
    value: InherentRiskScore.Low,
  },
  [InherentRiskScore.Medium]: {
    title: 'Medium',
    value: InherentRiskScore.Medium,
  },
  [InherentRiskScore.High]: {
    title: 'High',
    value: InherentRiskScore.High,
  },
  [InherentRiskScore.Critical]: {
    title: 'Critical',
    value: InherentRiskScore.Critical,
  },
}

export interface InherentRiskLevelColorOption {
  color: string
  background: string
}

export const inherentRiskLevelColors: Record<string, InherentRiskLevelColorOption> = {
  [InherentRiskScore.Low]: {
    color: theme.palette.RiskLevel.Acceptable,
    background: theme.palette.RiskLevel.AcceptableFaded,
  },
  [InherentRiskScore.Medium]: {
    color: theme.palette.RiskLevel.Tolerable,
    background: theme.palette.RiskLevel.TolerableFaded,
  },
  [InherentRiskScore.High]: {
    color: theme.palette.RiskLevel.Critical,
    background: theme.palette.RiskLevel.CriticalFaded,
  },
  [InherentRiskScore.Critical]: {
    color: theme.palette.RiskLevel.Extreme,
    background: theme.palette.RiskLevel.ExtremeFaded,
  },
}
