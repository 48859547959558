import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { useAuth } from 'utils/hooks'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useCombinedOnboardingProgress } from './utils/useCombinedOnboardingProgress'

const clientToken = 'i7zM1wjSlw'

export const Home: React.FC = () => {
  const breadcrumbs = [{ title: 'Home' }]
  const [isMounted, setIsMounted] = useState<boolean>(false)

  const { userData } = useAuth()
  const userOrgCode = userData?.organizationCode

  useCombinedOnboardingProgress({
    enabled: !!userData?.userId && !!userOrgCode,
    onSuccess: data => {
      const initCandu = () => {
        ;(window as any)?.Candu?.init({
          clientToken,
          userId: userData?.userId || '',
          traits: {
            tenant: userOrgCode,
            ...data,
          },
        })
        setIsMounted(true)
      }

      if (!(window as any)?.Candu && !isMounted) {
        const script = document.createElement('script')

        script.setAttribute('src', `https://cdn.candu.ai/sdk/latest/candu.umd.js?token=${clientToken}`)
        script.onload = initCandu
        document.head.appendChild(script)
      } else {
        initCandu()
      }
    },
    onError: () => {
      showToast({ content: 'Failed to update progress', type: 'error' })
    },
  })

  if (!isMounted) return <Spinner size="32px" />

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" alignItems="center" maxWidth={1280}>
        <Box display="flex" flexDirection="column">
          <div id="youcanduit" />
        </Box>
      </Box>
    </>
  )
}
