import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { ContentGroup, UpsertLayout, UpsertLayoutMode, Spinner } from '@ketch-com/deck'
import { MaybeNull } from 'interfaces/common'
import { ApplicationDTO } from 'interfaces/applications/application'
import { RoutesManager } from 'utils/routing/routesManager'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { ApplicationAccessibility } from 'pages/consentAndRights/applications/upsert/components/ApplicationAccessibility'
import { ApplicationDocuments } from 'pages/consentAndRights/applications/upsert/components/ApplicationDocuments'
import { ApplicationBasicDetails } from 'pages/consentAndRights/applications/upsert/components/ApplicationBasicDetails'
import { ApplicationDataLayer } from 'pages/consentAndRights/applications/upsert/components/ApplicationDataLayer'
import { ApplicationEnvironments } from 'pages/consentAndRights/applications/upsert/components/ApplicationEnvironments'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { PolicyDocumentDTO } from 'interfaces/policyDocuments/policyDocument'
import { ApplicationPlatform } from 'interfaces/applications/applicationPlatform'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { useApplicationValidationSchema } from '../utils'
import { getEditApplicationInitialValues } from './utils'

type Props = {
  isReady: boolean
  application: MaybeNull<ApplicationDTO>
  dataSubjectTypes: DataSubjectTypeDTO[]
  themes: ThemeV3DTO[]
  policyDocuments: PolicyDocumentDTO[]
  identitySpacesList: IdentitySpaceDTO[]
  onSubmit: (values: ApplicationFormValues) => Promise<void>
}

export const ApplicationEdit: FC<Props> = ({
  isReady,
  application,
  dataSubjectTypes,
  themes,
  policyDocuments,
  identitySpacesList,
  onSubmit,
}) => {
  const navigate = useNavigate()
  const validationSchema = useApplicationValidationSchema({ isEditMode: true })

  const name = application?.name || ''
  const code = application?.code || ''

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Properties', link: RoutesManager.deployment.applications.root.getURL() },
    {
      title: name || code,
      link: RoutesManager.deployment.applications.view.root.getURL({ code }),
    },
    { title: 'Edit Property' },
  ]

  const initialValues = useMemo(() => getEditApplicationInitialValues({ application }), [application])

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  })

  const { isSubmitting, submitForm, values } = formikProps

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          mode={UpsertLayoutMode.edit}
          renderFormTitle={() => <ContentGroup title="Edit Property" titleVariant="h2" isDivider />}
          acceptButtonProps={{ pending: isSubmitting, disabled: isSubmitting || !isReady }}
          cancelButtonProps={{ pending: isSubmitting }}
          onAccept={submitForm}
          onCancel={() => {
            navigate(RoutesManager.deployment.applications.view.root.getURL({ code }))
          }}
        >
          {isReady ? (
            <Box pt={2} component="form" autoComplete="off">
              <ApplicationBasicDetails isEditMode dataSubjectTypes={dataSubjectTypes} />
              {isRelease(Release.PropertyThemeDocuments) && (
                <ApplicationDocuments isEditMode policyDocuments={policyDocuments} />
              )}
              {values.platform !== ApplicationPlatform.CTV && <ApplicationAccessibility />}
              <ApplicationEnvironments themes={themes} />
              <ApplicationDataLayer identitySpacesList={identitySpacesList} />
            </Box>
          ) : (
            <Box
              sx={{
                margin: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
