import { Checkbox, Icon, Spinner, TextInput } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { useDebounce } from 'react-use'

export interface SystemListFilterOption {
  name: string
  isSelected: boolean
  id?: string
}

export type FiltersData = Record<string, SystemListFilterOption[]>

export interface SystemListFilterParams {
  name: string
  label?: string
  options: SystemListFilterOption[]
  setFilters: Dispatch<SetStateAction<FiltersData>>
  setCount: Dispatch<SetStateAction<Record<string, number>>>
  isLoading: boolean
}
export const SystemListFilter: React.FC<SystemListFilterParams> = ({
  name,
  label,
  options,
  isLoading,
  setFilters,
  setCount,
}) => {
  const [searchString, setSearchString] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')

  useDebounce(
    () => {
      if (searchString) {
        setSearchValue(searchString)
      } else {
        setSearchValue('')
      }
    },
    500,
    [searchString],
  )

  const filteredOptions = searchValue
    ? options.filter(option => {
        const isChecked = option.isSelected
        return option.name.toLowerCase().includes(searchValue.toLowerCase()) || isChecked
      })
    : options

  const placeholder = `Search ${label}`

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center" width="100%">
      <Box paddingX={1.5} paddingTop={1} paddingBottom={1.5} width="100%">
        <TextInput
          sx={{ mb: 1 }}
          startIcon={<Icon name="OMag" />}
          endIcon={
            searchString && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => setSearchString('')} />
          }
          size="small"
          fullWidth
          placeholder={placeholder}
          onChange={e => setSearchString(e.target.value)}
        />
      </Box>

      {isLoading ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
          <Spinner size="16px" thickness={2} />
        </Box>
      ) : !options.length || !filteredOptions.length ? (
        <Box height={34} width="100%" pl={1.5}>
          <Typography color="darkDuskFaded.main">No results found</Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{ bgcolor: 'background.paper', maxHeight: 200, overflow: 'auto', width: '100%' }}
        >
          {filteredOptions.map(option => {
            return (
              <Box key={option.name} display="flex" alignItems="center" padding="1px 3px">
                <Checkbox
                  id={option.name}
                  name={option.name}
                  title={option.name}
                  checked={option.isSelected}
                  onClick={() => {
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      [name]: prevFilters[name].map(filterItem =>
                        filterItem.name === option.name
                          ? { ...filterItem, isSelected: !option.isSelected }
                          : filterItem,
                      ),
                    }))
                    setCount((prevCount: Record<string, number>) => {
                      return {
                        ...prevCount,
                        [name]: option.isSelected ? prevCount[name] - 1 : prevCount[name] + 1,
                      }
                    })
                  }}
                />

                <Typography
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  {option.name}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
