import { Button, Icon, MegaFilter, MegaFilterOptionType } from '@ketch-com/deck'
import { SystemListFilter } from './SystemListFilter'
import { useSystemListFilterContext } from './SystemListFilterV2Context'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useCallback, useMemo } from 'react'
import { FilterCategories } from 'api/dataSystems/queries/useInstalledDataSystemsInfiniteV2List'

export const SystemListMegaFilter: React.FC = () => {
  const { filterData, isLoading, setFilterData, sections, setCounts, filterButtonText, resetFilters } =
    useSystemListFilterContext()

  const selectedFilterValues = useMemo(() => {
    const filterValues: Record<string, MegaFilterOptionType[]> = {}
    for (const key in filterData) {
      filterValues[key] = filterData[key as FilterCategories]
        .filter(option => option.isSelected)
        .map(selectedFilter => ({
          value: selectedFilter.id!,
          label: selectedFilter.name,
          category: key,
        }))
    }
    return filterValues
  }, [filterData])

  const onDeselectChipHandler = useCallback(
    (option: MegaFilterOptionType) => {
      setFilterData(prev => ({
        ...prev,
        [option.category]: prev[option.category as FilterCategories].map(values => {
          if (values.id === option.value) {
            return {
              ...values,
              isSelected: false,
            }
          }
          return values
        }),
      }))
      setCounts(prev => ({
        ...prev,
        [option.category]: prev[option.category] - 1,
      }))
    },
    [setCounts, setFilterData],
  )

  // inherent risk demo entitlement
  const { isEntitled } = useIsEntitled()
  const isEntitledToDemo = isEntitled(ENTITLEMENTS.DEMO)

  return (
    <MegaFilter
      sx={{
        '& .MuiButtonBase-root': {
          alignSelf: 'flex-start',
        },
        '& .MegaFilter-selectedOptions': {
          '& .MuiButtonBase-root': {
            alignSelf: 'center',
          },
        },
      }}
      value={selectedFilterValues}
      sections={sections}
      onDeselectChipHandler={onDeselectChipHandler}
      buttonTitle={filterButtonText}
      buttonProps={{
        size: 'medium',
        color: 'secondary',
        endIcon: <Icon name="OArrowCDown" />,
        pending: isLoading,
        sx: {
          '& .DeckBtnWrapChild': {
            whiteSpace: 'nowrap',
          },
        },
      }}
      renderActionItems={
        <Button
          sx={{ marginBlock: 1 }}
          color="tertiary"
          onClick={() => {
            resetFilters()
          }}
        >
          Clear All
        </Button>
      }
    >
      {sections
        .filter(section => {
          if (!isEntitledToDemo && section.value === 'inherentRisk') {
            return false
          }
          return true
        })
        .map(section => {
          return (
            <SystemListFilter
              key={section.value}
              name={section.value}
              label={section.label}
              options={filterData[section.value]}
              setFilters={setFilterData}
              isLoading={isLoading}
              setCount={setCounts}
            />
          )
        })}
    </MegaFilter>
  )
}
